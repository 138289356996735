import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import clsx from 'clsx'
import Image from 'next/image'
import type { TestimonialType } from '../../types'
import styles from './Testimonial.module.scss'

const Testimonial = ({ text, image, company, name, title, profileSlug }: TestimonialType) => (
  <div className={styles.testimonial}>
    <Typography as="p" variant="paragraph" className={styles.testimonials_text}>
      &ldquo;{text}&ldquo;
    </Typography>
    <Link className={styles.testimonial_profile} href={`/profile/${profileSlug}`}>
      {image && (
        <Image
          src={image}
          alt={`${name}'s profile image`}
          width="60"
          height="60"
          className={styles.testimonial_profile_img}
          unoptimized
        />
      )}
      <div
        className={clsx(styles.testimonial_profile_text, {
          [styles.without_avatar]: !image,
        })}
      >
        <Typography as="p" variant="paragraph">
          <b>{name}</b>
        </Typography>
        <Typography as="p" variant="paragraph" size="small">
          {title}
          <br />
          {company}
        </Typography>
      </div>
    </Link>
  </div>
)

export default Testimonial
