'use client'
import Link from '@/components/core/Link'
import Typography from '@/components/core/Typography'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import styles from './Testimonials.module.scss'
import SliderButtons from './sub/SliderButtons'
import Testimonial from './sub/Testimonial'
import { MIN_DESKTOP_WIDTH, SLIDER_NEXT, SLIDER_PREV, type TestimonialsType } from './types'

type TestimonialsProps = TestimonialsType & { sliderClassName?: string }

const Testimonials = ({ title, testimonials, sliderClassName }: TestimonialsProps) => {
  const [current, setCurrent] = useState(0)
  const [totalPages, setTotalPages] = useState<number>(testimonials.length / 3)

  useEffect(() => {
    const handleResize = () => {
      const isDesktop = window.innerWidth >= MIN_DESKTOP_WIDTH
      const div = isDesktop ? 3 : 1
      const pages = Math.ceil(testimonials.length / div)
      setTotalPages(pages)
      setCurrent(0)
    }
    window.addEventListener('resize', handleResize, false)
    handleResize()
    return () => window.removeEventListener('resize', handleResize, false)
  }, [testimonials])

  const changeSlider = (action: string) => {
    if (action === SLIDER_NEXT && current < totalPages) {
      setCurrent((current) => ++current)
    } else if (action === SLIDER_PREV && current > 0) {
      setCurrent((current) => --current)
    }
  }

  return (
    <section className={styles.testimonials}>
      <Typography as="h2" variant="h2" className={styles.title}>
        {title}
      </Typography>
      <div className={styles.testimonials_content}>
        <div
          className={clsx(styles.testimonials_wrapper, sliderClassName)}
          data-testid="testimonials_wrapper"
          style={{ transform: `translateX(${-(current * 100)}%)` }}
        >
          {testimonials.map((testimonial) => (
            <Testimonial {...testimonial} key={testimonial.title} />
          ))}
        </div>
      </div>
      {totalPages && totalPages > 1 && <SliderButtons onChange={changeSlider} current={current} total={totalPages} />}
      <Link className={styles.view_all} href={'/ambassadors'}>
        View All Ambassadors
      </Link>
    </section>
  )
}

export default Testimonials
