import type { GravityComponentType } from '@/components/gravityTypes'
import clsx from 'clsx'
import type React from 'react'
import { useState } from 'react'
import styles from './CollapsibleItem.module.scss'

type CollapsableItemProps<T extends React.ElementType> = {
  as?: T
  open?: boolean
  header: React.ReactElement
  headerExpanded?: React.ReactElement
} & React.ComponentPropsWithoutRef<T> &
  GravityComponentType

const CollapsableItem = <T extends React.ElementType = 'div'>({
  as,
  open = false,
  header,
  headerExpanded,
  children,
  ...props
}: CollapsableItemProps<T>) => {
  const [show, setShow] = useState(open)
  const Component = as || 'div'
  const expandedElement = headerExpanded || header
  return (
    <Component {...props}>
      <div onClick={() => setShow(!show)} className={clsx(styles.header, styles[`${show}`])}>
        {(show && expandedElement) || header}
      </div>
      <div className={clsx(styles.content, styles[`${show}`])}>{children}</div>
    </Component>
  )
}

export default CollapsableItem
